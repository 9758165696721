import { useEffect } from "react";

const OAStudy = () => {
	useEffect(() => {
		window.location.replace(
			"https://play.google.com/store/apps/details?id=com.onusant.learnohub"
		);
	}, []);
};

export default OAStudy;
