import { useEffect } from "react";

const BehrorBusiness = () => {
	useEffect(() => {
		window.location.replace(
			"https://play.google.com/store/apps/details?id=com.onusant.behrorbusiness"
		);
	}, []);
};

export default BehrorBusiness;
