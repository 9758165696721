import { useEffect } from "react";

const OnusAnt = () => {
	useEffect(() => {
		window.location.replace(
			"https://play.google.com/store/apps/dev?id=5751302584145160415"
		);
	}, []);
};

export default OnusAnt;
