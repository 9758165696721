import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BehrorBusiness from "./component/BehrorBusiness";
import OAStudy from "./component/OAStudy";
import OnusAnt from "./component/OnusAnt";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="*" element={<OnusAnt />} />
				<Route path="/" element={<OnusAnt />} />
				<Route path="/oa-study" element={<OAStudy />} />
				<Route path="/behror-business" element={<BehrorBusiness />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
reportWebVitals();
